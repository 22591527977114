.booking-item {
  .booking-top-infos {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 1rem;

    .booking-status {
      .state-circle {
        width: 0.8rem;
        height: 0.8rem;
        display: inline-block;
        border-radius: 100%;
        margin-right: 0.5rem;
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
  .booking-main-infos {
    display: flex;
    justify-content: space-between;
  }
  .booking-type {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .booking-details {
    .btn {
      margin-bottom: 1rem;
    }
    .label {
      font-weight: 600;
      font-size: 0.9rem;
    }

    .address {
      h4 {
        font-size: 1rem;
      }
    }
  }
}

#modal-booking-sent-messages {
  .modal-body {
    background-color: #f2f2f2cc;
  }
  .card.message {
    &:after {
      margin: 0;
    }
    .card-date {
      font-size: 0.8rem;
    }

    .attachments.link-list {
      margin-bottom: 0;
    }
    .more {
      margin-bottom: -1.5rem;
    }
  }
}
