.form-group {
  input.is-invalid.form-control {
    background-color: #fff;

    & + label.active {
      color: var(--danger);
    }
  }

  small.invalid-feedback {
    display: inline;
  }
}
