body.public-ui {
  .prenotazione-view {
    .section-form {
      &--card {
        padding: var(--padding-x-section);
        border-radius: 0;
        margin-bottom: 1rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
        background-color: var(--section-bg-grey);
      }
    }
    .steppers {
      .navbar-nav {
        width: 100%;
        flex-direction: unset;
        justify-content: space-between !important;
        .nav-item:only-child {
          margin-left: auto;
        }
      }
    }

    /* RADIO CARD */
    .radio-card {
      &.border-focus-primary {
        outline: 2px solid var(--primary);
      }

      .form-check {
        input[type='radio'] {
          pointer-events: none;

          &:focus {
            & + label {
              border: 0;
              box-shadow: none !important;
            }
          }

          & + label {
            width: 100%;
            height: auto;
            margin-bottom: 0;

            &:focus {
              border: 0;
              box-shadow: none !important;
            }
          }
        }
      }

      &--info p {
        font-size: var(--font-size-sm);
        margin-bottom: 0;

        &.p-info {
          box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);
          padding-bottom: 0.5rem;
          margin-bottom: 1rem;
        }
      }
    }

    /* BUTTONS */
    .steppers-nav {
      border-top: 1px solid var(--section-bg-grey);
      flex-wrap: wrap;
      height: auto;

      button {
        min-width: var(--min-width-button);
      }
    }

    /* STEP DATA E ORA */
    .prenotazioneDataEOra {
      .bootstrap-select-wrapper {
        .react-select__single-value,
        .react-select__menu .react-select__option {
          text-transform: capitalize;
        }
      }

      .unbookable-types {
        color: var(--danger);
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }

    /* FORM RICHIEDENTE */
    .prenotazioneRichiedente, .prenotazioneDettagli {
      .form-group label {
        pointer-events: none;
      }

      textarea[readonly] {
        background: var(--input-disabled-bg);
        cursor: not-allowed;
      }
    }

    #prenotazioneRiepilogo {
      .section-form--card .card-wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }

  #modal-delete-prenotazione {
    .load-delete-icon {
      margin-left: 1.15rem;
      margin-right: 1.15rem;
      animation: loading 2s linear infinite;
      @keyframes loading {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
